export const indexMenuLinks = [
  {
    name: 'About',
    link: '/#about',
    icon: 'user-tie',
  },
  {
    name: 'Projects',
    link: '/#projects',
    icon: 'terminal',
  },
  
  //{
   // name: 'Blog',
   // link: '/#blog',
   // icon: 'feather-alt',
  //}, 
  {
    name: 'Contact',
    link: '/#contact',
    icon: 'envelope',
  },
];

export const blogMenuLinks = [
  {
    name: 'Projects',
    link: '/projects',
    icon: 'code',
  },
  
  //{
   // name: 'Blog',
   // link: '/blog',
   // icon: 'feather-alt',
  //},
  /*
  {
    name: 'Tags',
    link: '/tags',
    icon: 'tags',
  },
  */
  {
    name: 'Contact',
    link: '/#contact',
    icon: 'envelope',
  },
];
