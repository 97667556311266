export const socialIconList = [
  {
    name: 'twitter',
    prefix: 'fab', // fab prefix for brands, fas for solid icons
    link: 'https://twitter.com/logical_star_co',
  },
  {
    name: 'github',
    prefix: 'fab',
    link: 'https://github.com/Logical-Star-Consulting',
  },
  //{
  //  name: 'codepen',
  //  prefix: 'fab',
  //  link: 'https://codepen.io/',
  //},
  // Add further social links with the icon of choice and link here
  // check https://fontawesome.com/icons?d=gallery&s=brands for other brand icons
];
